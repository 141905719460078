import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`1. Purpose`}</h3>
    <p>{`The current document describes the minimal infrastructure and resources needed to deploy a new voice application zone in a new datacenter to support CoreMedia Cloud Contact Center. `}</p>
    <p>{`These deployments are of two kinds depending on the type of usage: `}</p>
    <ul>
      <li parentName="ul">{`Proof Of Concept (POC) `}</li>
      <li parentName="ul">{`Production Deployment`}</li>
    </ul>
    <h3>{`2. Deployment POC`}</h3>
    <h4>{`1. Network Topology Architecture:`}</h4>
    <h4>{`2. Connectivity requirements`}</h4>
    <p>{`The voice services from CoreMedia require both Internet and VoIP access connections to be able to properly work. With that in mind there are requirements that must be met besides the connectivity. Since the present deployment is for a POC, the solution doesn’t need to be highly available. The requirements are below: `}</p>
    <ul>
      <li parentName="ul">{`Internet: `}
        <ul parentName="li">
          <li parentName="ul">{`Two internet circuits with 30 Mbps or higher with failover`}</li>
          <li parentName="ul">{`High-Available IPSec VPN Site-to-site capability between new infrastructure and CoreMedia VPN aggregator `}</li>
          <li parentName="ul">{`Ability to upgrade`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Voice:`}
        <ul parentName="li">
          <li parentName="ul">{`Two SIP trunk circuits `}</li>
          <li parentName="ul">{`SIP Trunk channels: The number of channels may vary depending on the size of the project`}</li>
          <li parentName="ul">{`National and international calls`}</li>
          <li parentName="ul">{`Codec: G.711a (alaw / ulaw)`}</li>
          <li parentName="ul">{`Both SIP trunk should be highly available and redundant`}</li>
          <li parentName="ul">{`The inbound calls should be delivered in a round-robin pattern`}</li>
          <li parentName="ul">{`Ability to upgrade`}</li>
        </ul>
      </li>
    </ul>
    <h4>{`3. Computing requirements`}</h4>
    <p>{`The voice services can be deployed using virtual machines or physical servers. On both scenarios, the computing requirements need to be taken into account are:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Physical Servers`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Virtual Machine`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of Instances`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CPU`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RAM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8-16GB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8-16GB`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HDD(at least)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100G`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100G`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Network Interfaces`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 (with failover recommended)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Power Supply`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 (redundant)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remote Console   (iLO, KVM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operating System`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CentOS 7, Debian 10, RHEL 7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CentOS 7, Debian 10, RHEL 7`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Since the present deployment is for a POC, the solution doesn’t need to be highly available. `}</p>
    <h3>{`2. Production Deployment`}</h3>
    <h4>{`1. Network Topology Architecture`}</h4>
    <h4>{`2. Connectivity requirements`}</h4>
    <p>{`The voice services from CoreMedia require both Internet and VoIP access connections to be able to properly work. With that in mind there are requirements that must be met besides the connectivity. Since the present deployment is for production, the solution needs to be highly available and redundant. The requirements are below:`}</p>
    <ul>
      <li parentName="ul">{`Internet: `}
        <ul parentName="li">
          <li parentName="ul">{`Two internet circuits with 30 Mbps or higher with failover`}</li>
          <li parentName="ul">{`High-Available IPSec VPN Site-to-site capability between new infrastructure and CoreMedia VPN aggregator `}</li>
          <li parentName="ul">{`Ability to upgrade`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Voice:`}
        <ul parentName="li">
          <li parentName="ul">{`Two SIP trunk circuits `}</li>
          <li parentName="ul">{`SIP Trunk channels: The number of channels may vary depending on the size of the project`}</li>
          <li parentName="ul">{`National and international calls`}</li>
          <li parentName="ul">{`Codec: G.711a (alaw / ulaw)`}</li>
          <li parentName="ul">{`Both SIP trunk should be highly available and redundant`}</li>
          <li parentName="ul">{`The inbound calls should be delivered in a round-robin pattern`}</li>
          <li parentName="ul">{`Ability to upgrade`}</li>
        </ul>
      </li>
    </ul>
    <h4>{`3. Computing requirements`}</h4>
    <p>{`The voice services can be deployed using virtual machines or physical servers. On both scenarios, the computing requirements need to be taken into account are:`}</p>
    <p>{`Voice Servers:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Physical Servers`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Virtual Machine`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of Instances`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CPU`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RAM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16-32GB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16-32GB`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HDD(at least)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1TB RAID1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1TB`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Network Interfaces`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 (with failover recommended)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Power Supply`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 (redundant)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remote Console   (iLO, KVM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operating System`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CentOS 7, Debian 10, RHEL 7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CentOS 7, Debian 10, RHEL 7`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Application Servers:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Physical Servers`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Virtual Machine`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of Instances`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CPU`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RAM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16-32GB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16-32GB`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HDD(at least)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`300GB RAID1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`300GB`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Network Interfaces`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 (with failover recommended)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Power Supply`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 (redundant)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remote Console   (iLO, KVM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operating System`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CentOS 7, Debian 10, RHEL 7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CentOS 7, Debian 10, RHEL 7`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Since the present deployment is for a POC, the solution doesn’t need to be highly available.`}</p>
    <h3>{`3. New Site Checklist`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type of   Deployment`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Proof of Concept`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Production`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Connectivity   Requirements - Internet`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bandwidth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VPN IPSec`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`If No, what other type of VPN is available`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ability to upgrade`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`High-Available`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Connectivity   Requirements - SIP Trunk`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of Channels`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`National and International calls`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Coded`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ability to upgrade`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`High-Available`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Computing   requirements`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of Instances`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CPU`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RAM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HDD (at least)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Network interfaces`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Power supply`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remote console (iLO, KVM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operating system`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      